<template>
	<div class="help position-relative">
        <!-- шапка блока-->
        <div class="p-20 mb-20 bg-white border-bottom border-brd-primary position-relative">
            <div class="page-header">
                <h1 class="page-title mb-0">Обучение</h1>
            </div>
        </div>
        <!--контент-->

		<div class="container">
			<div class="row-fluid">
				<ul class="col m-0 p-0">
					<li v-for="(video, i) of this.video_list"
						:key="'video_' + i"
						class="bg-white rounded-3 mb-20 p-0">
						<div class="ratio ratio-16x9 mb-1">
							<iframe :src="video.src" :title="video.title" allowfullscreen>
<!--								<img
									:src="require('@/assets/img/video_mock.png')"
									alt="Заглушка для видео"
									width="400" height="270"
									class="video_mock"
								>-->
							</iframe>
						</div>
						<h2 class="fs-3 fw-semi font-semi px-20 pb-20 pt-10">{{video.title}}</h2>
					</li>
				</ul>
				<Pagination :initialPage="1"
							:itemsList="this.helpList"
							:labels="{
										first: false,
										last: false,
										previous: false,
										next: false
									}"
							:pageSize="this.page_size"
							:disableDefaultStyles="true"
							@changePage="(page) => this.page_number = page"
							:maxPages="3" />
			</div>
		</div>
	</div>
</template>

<script>
    import CommonService from "../services/CommonService";
	import Pagination from "@/components/common/Pagination";

    export default {
        name: "help",

        components: {
			Pagination
        },

        data() {
            return {
				title: 'Помощь, обучение',
				page_size: 5,
				page_number: 1,
                helpList: [
					{
						title: 'Полный обзор CRM для агентства недвижимости JoyWork',
						src: 'https://www.youtube.com/embed/WEUdcnMbP_M'
					},
					{
						title: 'JoyWork - система бронирования квартир от застройщиков',
						src: 'https://www.youtube.com/embed/KlzSbXj29J0'
					},
					{
						title: 'Сквозная аналитика в CRM для недвижимости JoyWork',
						src: 'https://www.youtube.com/embed/vKXCobDNJg4'
					},
					{
						title: 'Настройка воронок продаж в CRM "JoyWork" - оптимизация бизнес процессов в АН',
						src: 'https://www.youtube.com/embed/Nwpec0DUQ3M'
					},
					{
						title: 'Рекламные пакеты и планирование рекламы в CRm JoyWork',
						src: 'https://www.youtube.com/embed/U19mNzumAWo'
					},
					{
						title: 'Добавление сотрудников и настройка отделов в CRM для агентства недвижимости JoyWork',
						src: 'https://www.youtube.com/embed/PbzMiDSGkWY'
					},
					{
						title: 'Как обойти встречного агента и заработать 100 % комиссии?',
						src: 'https://www.youtube.com/embed/_sXp-AvjUGk'
					},
					{title: 'Размещение объектов в VK.com', src: 'https://www.youtube.com/embed/blSUHAhisDw'},
					{
						title: 'Ведение клиентов и воронки продаж в CRM для риэлторов JoyWork',
						src: 'https://www.youtube.com/embed/xen8-UbY5Ko'
					},
					{
						title: 'Как выставить свой объект на 80 досок по недвижимости?',
						src: 'https://www.youtube.com/embed/tUwZwk-CZlA'
					},
					{
						title: 'Один из способов привлечения и удержания клиентов в агентстве недвижимости',
						src: 'https://www.youtube.com/embed/kaZcS7YMhiA'
					},
					{
						title: 'Настройка таргетированной рекламы Вконтакте при помощи JoyWork.ru',
						src: 'https://www.youtube.com/embed/BaN2rO-Bpa0'
					},
					{
						title: 'Разделение клиентов на назначение деятельности, присвоение тегов к клиентам',
						src: 'https://www.youtube.com/embed/-LIeLus5jAg'
					},
					{title: 'Интеграция телефонии в JoyWork', src: 'https://www.youtube.com/embed/K9eV3yqOjzc'},
					{title: 'Кабинет клиента в JoyWork', src: 'https://www.youtube.com/embed/IB510YbKqg0'},
					{
						title: 'Раздел комиссии между агентами на JoyWork - (Партнерские сделки)',
						src: 'https://www.youtube.com/embed/N_zXM5jPavo'
					},
					{
						title: 'Заказ сведений ЕГРН из CRM системы JoyWork',
						src: 'https://www.youtube.com/embed/iH5rTig3noI'
					},
					{
						title: 'Отправка предложений клиенту на Whats App из CRM для риэлторов JoyWork',
						src: 'https://www.youtube.com/embed/js-kwGf7Jr8'
					},
					{title: 'Раздел Заявки в CRM JoyWork', src: 'https://www.youtube.com/embed/cD7LDPB0uuM'},
					{title: 'Виджет приема звонка в CRM JoyWork', src: 'https://www.youtube.com/embed/ieWOSqeAoUc'},
					{title: 'Аналитика по конкурентам в CRM JoyWork', src: 'https://www.youtube.com/embed/uNc1ga2x0KI'},
					{
						title: 'Настройка телефонов в рекламных источниках',
						src: 'https://www.youtube.com/embed/M0S69IQrtSo'
					},
					{
						title: 'Статистика по объектам и затратам на рекламу',
						src: 'https://www.youtube.com/embed/YdyazC7kV2A'
					},
					{
						title: 'Документооборот риелтора в CRM для недвижимости JoyWork',
						src: 'https://www.youtube.com/embed/0oROIw0m9fM'
					},
					{title: 'Фиксация заявок под объекты компании', src: 'https://www.youtube.com/embed/GpExuciI5xI'},
					{
						title: 'Автопоиск в CRM для Агентств недвижимости JoyWork',
						src: 'https://www.youtube.com/embed/nlMvEZybWM8'
					},
					{
						title: 'Добавление своих полей в карточку объекта и выгрузка этих полей в xml фиды в CRM JoyWork',
						src: 'https://www.youtube.com/embed/yuL06Gn5tIM'
					},
					{
						title: 'Управление типами размещений на площадках из СРМ и настройка интеграции по АПИ',
						src: 'https://www.youtube.com/embed/xS5rHM7pgpo'
					},
					{
						title: 'Выставление плана продаж и контроль выполнения, в CRM для недвижимости JoyWork.',
						src: 'https://www.youtube.com/embed/xS4_lHJgyGY'
					},
					{
						title: 'Подсчет затрат на рекламу Объектов и Выставление лимитов размещений для сотрудников, в CRM для недвижимости JoyWork.',
						src: 'https://www.youtube.com/embed/qH8bGkN_iK4'
					},
                ]
            };
        },
        methods: {
			paginate(array, page_size, page_number) {
				return array.slice((page_number - 1) * page_size, page_number * page_size);
			}
        },
		computed: {
			video_list() {
				return this.paginate(this.helpList, this.page_size, this.page_number);
			}
		},
        mounted() {
            CommonService.setPageTitle(this.title);
        },
    };
</script>

<style>
    .video_mock {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
</style>